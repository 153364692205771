import React from "react";
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import CategoryTemplate from "../../../templates/category-page";
const coins = [
  "ethereum",
  "binancecoin",
  "solana",
  "cardano",
  "avalanche-2",
  "terra-luna",
  "polkadot",
  "matic-network",
  "near",
  "chainlink",
  "tron",
  "algorand",
  "stellar",
  "vechain",
  "internet-computer",
  "tezos",
  "defichain",
  "blockstack",
];
const title = "Smart Contracts";
const description = `Ein Smart Contract, im Deutschen auch intelligenter Vertrag, basiert auf Computerprotokollen. Hierbei handelt es sich somit
um eine Art digitalen Vertrag, der auf der Blockchain-Technologie basiert. Die Bedingungen der Vereinbarung zwischen
„Käufer“ und „Verkäufer“ werden dabei direkt in Codezeilen geschrieben. Smart Contracts sind selbstausführende Verträge, das
heißt, dass sie bei bestimmten zuvor festgelegten Ereignissen selbständig in Kraft treten und daher auch keiner menschlichen
Überwachung bedürfen. Sind diese Eintrittsbedingungen erfüllt, so veranlasst der Algorithmus automatisch eine Transaktion,
welche anschließend validiert und in einem Block gespeichert wird. Smart Contracts ermöglichen somit die Durchführung
vertrauenswürdiger Transaktionen und Vereinbarungen zwischen verschiedenen Parteien. Diese digitalen Verträge sind durchaus
mit klassischen Verträgen vergleichbar – beispielsweise mit einem Kaufvertrag oder dem Abschluss einer Versicherungspolice.
Da ein solcher intelligenter Vertrag ohne menschliches Eingreifen abgewickelt wird, lassen sich auch typische Fehlerquellen
ausschließen.`;

const Page: React.FC = () => {
  return (
    <Layout>
      <SEO keywords={[title]} title={title} description={description} />
      <CategoryTemplate title={title} coins={coins} description={description} coingeckoId="smart-contract-platform" />
    </Layout>
  );
};

export default Page;
